<template>
  <div>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">

        <!-- sorting  -->
        <b-form-group
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative detail-view"
      :tbody-tr-class="rowClass"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >

      <template #cell(show_details)="row">
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <feather-icon
          :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
          size="18"
          @click="row.toggleDetails"
        />
      </template>

      <!-- full detail on click -->
      <template #row-details="row">
        <load-board-table-detail-view
          ref="detailView"
          :item="row.item"
        />
      </template>

      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ 'min-width': '50px' }"
        >
      </template>

      <!-- accountant status -->
      <template #cell(acc_status)="row">
        <b-badge
          :variant="row.item.load.acc_status === 'Invoiced' ? 'success' : 'warning'"
        >
          {{ row.item.load.acc_status }}
        </b-badge>
      </template>

      <!-- company name -->
      <template #cell(company_name)="row">
        {{ row.item.load.company_name }}
      </template>

      <!-- operation status -->
      <template #cell(operation_status)="row">
        <b-badge
          :variant="row.item.load.operation_status === 'Completed' ? 'success' : row.item.load.operation_status === 'Booked' ? 'warning' :'info'"
        >
          {{ row.item.load.operation_status }}
        </b-badge>
      </template>

      <!-- pickup driver -->
      <template #cell(pickup_driver)="row">
        <ul>
          <li
            v-for="(pickup, index) in row.item.pickups"
            :key="index"
          >
            <span v-if="pickup.firstname || pickup.lastname">
              {{ `${pickup.firstname ? pickup.firstname : ''} ${pickup.lastname ? pickup.lastname : ''} (${pickup.truck ? pickup.truck : ''}/${pickup.trailer ? pickup.trailer : ''})` }}
            </span>
          </li>
        </ul>
      </template>

      <!-- pickup address -->
      <template #cell(pickup_address)="row">
        <ul>
          <li
            v-for="(pickup, index) in row.item.pickups"
            :key="index"
          >
            <span v-if="pickup.location || pickup.city || pickup.state || pickup.zip">
              {{ `${pickup.location}, ${pickup.city}, ${pickup.state}, ${pickup.zip}` }}
            </span>
          </li>
        </ul>
      </template>

      <!-- pickup time -->
      <template #cell(pickup_time)="row">
        <ul>
          <li
            v-for="(pickup, index) in row.item.pickups"
            :key="index"
          >
            <span v-if="pickup.actual_start || pickup.actual_end">
              {{ pickup.actual_start | datePipe }} - {{ pickup.actual_end | datePipe }}
            </span>
          </li>
        </ul>
      </template>

      <!-- delivery driver -->
      <template #cell(delivery_driver)="row">
        <ul>
          <li
            v-for="(delivery, index) in row.item.deliveries"
            :key="index"
          >
            <span v-if="delivery.firstname || delivery.lastname">
              {{ `${delivery.firstname ? delivery.firstname : ''} ${delivery.lastname ? delivery.lastname : ''} (${delivery.truck ? delivery.truck : ''}/${delivery.trailer ? delivery.trailer : ''})` }}
            </span>
          </li>
        </ul>
      </template>

      <!-- delivery address -->
      <template #cell(delivery_address)="row">
        <ul>
          <li
            v-for="(delivery, index) in row.item.deliveries"
            :key="index"
          >
            <span v-if="delivery.location || delivery.city || delivery.state || delivery.zip">
              {{ `${delivery.location}, ${delivery.city}, ${delivery.state}, ${delivery.zip}` }}
            </span>
          </li>
        </ul>
      </template>

      <!-- delivery time -->
      <template #cell(delivery_time)="row">
        <ul>
          <li
            v-for="(delivery, index) in row.item.deliveries"
            :key="index"
          >
            <span v-if="delivery.actual_start || delivery.actual_end">
              {{ delivery.actual_start | datePipe }} - {{ delivery.actual_end | datePipe }}
            </span>
          </li>
        </ul>
      </template>

      <!-- freight_details -->
      <template #cell(freight_details)="row">
        <p>Commodity: {{ row.item.load.commodity | commodity }}</p>
        <p>Weight: {{ row.item.load.weight }}</p>
        <p>Pallets: {{ row.item.load.pallets }}</p>
        <p>Cases: {{ row.item.load.cases }}</p>
        <p>Spots: {{ row.item.load.spots }}</p>
      </template>

      <!-- dispatcher name -->
      <template #cell(dispatcher)="row">
        {{ `${row.item.load.dispatcher_firstname ? row.item.load.dispatcher_firstname : ''} ${row.item.load.dispatcher_middlename ? row.item.load.dispatcher_middlename : ''} ${row.item.load.dispatcher_lastname ? row.item.load.dispatcher_lastname : ''}` }}
      </template>

      <!-- load id -->
      <template #cell(loadID)="row">
        {{ row.item.load.loadID }}
      </template>

      <!-- customer -->
      <template #cell(customer)="row">
        {{ row.item.load.customer_name }}
      </template>

      <!-- equipment type -->
      <template #cell(equipment)="row">
        {{ `${row.item.load.equipmentType === 'Trailer' ? `${row.item.load.trailer_type}, ${row.item.load.trailer_size}` : `${row.item.load.model}, ${row.item.load.year}`}` }}
      </template>

      <!-- action -->
      <template #cell(action)="row">
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <b-button
              variant="success"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item
            v-if="row.item.load.acc_status === 'Ready to Bill'"
            @click="createInvoice(row.item.load)"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
              class="mr-1"
            />Create Invoice
          </b-dropdown-item>

          <b-dropdown-item
            v-if="row.item.load.acc_status === 'Invoiced'"
            @click="finalized(row.item.load.id)"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="18"
              class="mr-1"
            />Finalized
          </b-dropdown-item>

          <b-dropdown-item
            @click="deleteLoad(row.item.load.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="mr-1"
            />Delete
          </b-dropdown-item>

        </b-dropdown>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import moment from 'moment'
import * as loadService from '@/services/load'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoadBoardTableDetailView from './DetailView.vue'

export default {
  components: {
    // BCardCode,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    LoadBoardTableDetailView,
  },
  filters: {
    datePipe(date) {
      if (date === null) return ''
      return moment(date).format('MM/DD/yyyy HH:mm')
    },
    commodity(data) {
      const array = data.filter((v, i) => data.indexOf(v) === i)
      return array.join()
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        'show_details',
        { key: 'acc_status', label: 'Acc Status', sortable: true },
        { key: 'operation_status', label: 'Operation Status', sortable: true },
        { key: 'pickup_driver', label: 'Pickup Driver ( Truck / Trailer)', sortable: true },
        { key: 'pickup_address', label: 'Pickup Address', sortable: true },
        { key: 'pickup_time', label: 'Pickup Time', sortable: true },
        { key: 'loadID', label: 'Load Id', sortable: true },
        { key: 'delivery_address', label: 'Delivery Address', sortable: true },
        { key: 'delivery_time', label: 'Delivery Time', sortable: true },
        { key: 'delivery_driver', label: 'Delivery Driver ( Truck / Trailer)', sortable: true },
        // { key: 'dispatcher', label: 'Dispatcher', sortable: true },
        { key: 'equipment', label: 'Equipment', sortable: true },
        // { key: 'important_note', label: 'Important Notes', sortable: true },
        { key: 'freight_details', label: 'Freight Details', sortable: true },
        { key: 'action', label: 'Action' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    // delete load
    deleteLoad(id) {
      loadService.deleteLoad(id)
        .then(res => {
          this.$emit('refresh')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },

    // send invoice to quickbook
    createInvoice(load) {
      const params = {
        id: load.id,
        rate_confirmation_amount: load.rate_confirmation_amount,
        tonu: load.tonu,
        detention: load.detention,
        others: load.others,
        expected_total: load.expected_total,
      }

      loadService.createInvoice(params)
        .then(res => {
          this.$emit('refresh')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // account status to finalized
    finalized(id) {
      loadService.finalized(id)
        .then(res => {
          this.$emit('refresh')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // if trailier type is Reefer then row color is blue
    rowClass(item, type) {
      const colorClass = 'table-info'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.load.trailer_type === 'Reefer') { return colorClass }
    },
  },
}
</script>
<style lang="scss">
  .detail-view table tbody .b-table-details td[colspan="8"] {
    padding-left: 0px;
    padding-right: 0px;
  }
  .detail-view .card {
    margin-bottom: 0rem;
  }
  p {
    margin-bottom: 1px;
  }
</style>

<template>
  <b-row>

    <b-col
      md="12"
      class="mb-1 d-flex justify-content-between align-items-center"
    >
      <b-button
        variant="primary"
        @click="$router.history.push({ name: 'add-load' })"
      >
        Add +
      </b-button>

      <feather-icon
        v-b-tooltip.hover.left
        title="You can scroll table by Shift + mouse scroll"
        icon="HelpCircleIcon"
        size="20"
      />
    </b-col>

    <b-col md="12">

      <b-card-code
        title=""
        no-body
      >
        <b-tabs
          align="center"
          pills
        >

          <b-tab title="Upcoming">

            <!-- upcoming table -->
            <load-board-upcoming-table
              :items="upcomings"
              @refresh="refresh"
            />

          </b-tab>

          <b-tab title="History">

            <!-- history table -->
            <load-board-history-table
              :items="histories"
              @refresh="refresh"
            />

          </b-tab>

          <b-tab title="Finalized">

            <!-- history table -->
            <finalize-table
              :items="finalizes"
              @refresh="refresh"
            />

          </b-tab>

          <!-- <b-tab title="Search">

            <load-board-filter-bar
              @search="search"
              @refresh="refresh"
            />

          </b-tab> -->
          
        </b-tabs>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BTabs, BTab, BButton, VBTooltip,
} from 'bootstrap-vue'
import * as loadService from '@/services/load'
import * as userService from '@/services/user'
import * as facilityService from '@/services/facility'
import * as assetService from '@/services/asset'
import LoadBoardUpcomingTable from './UpcomingTable.vue'
import LoadBoardHistoryTable from './HistoryTable.vue'
import FinalizeTable from './FinalizeTable.vue'
// import LoadBoardFilterBar from './FilterBar.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    LoadBoardUpcomingTable,
    LoadBoardHistoryTable,
    FinalizeTable,
    // LoadBoardFilterBar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loads: [],
      drivers: [],
      facilities: [],
      trucks: [],
      trailers: [],
      upcomings: [],
      histories: [],
      finalizes: [],
    }
  },
  provide() {
    const drivers = []
    const facilities = []
    const trucks = []
    const trailers = []

    // define provider object for drivers of details view
    Object.defineProperty(drivers, 'drivers', {
      enumerable: true,
      get: () => this.drivers,
    })

    // define provider object for facilities of detail view
    Object.defineProperty(facilities, 'facilities', {
      enumerable: true,
      get: () => this.facilities,
    })

    // define provider object for facilities of detail view
    Object.defineProperty(trucks, 'trucks', {
      enumerable: true,
      get: () => this.trucks,
    })

    // define provider object for facilities of detail view
    Object.defineProperty(trailers, 'trailers', {
      enumerable: true,
      get: () => this.trailers,
    })

    return {
      drivers, facilities, trucks, trailers,
    }
  },
  created() {
    this.getLoads()
    this.getDrivers()
    this.getAssets()
    this.getFacility()
  },
  methods: {
    search() {

    },

    // get driver lists
    async getDrivers() {
      this.drivers = await userService.getUsersByRole({ role: 'driver' })
    },

    // get facility codes
    async getFacility() {
      const res = await facilityService.getCodes()
      this.facilities = res.data.facilityCodes
    },

    // get loads
    getLoads() {
      this.$store.commit('set_loading_action', true)
      loadService.getLoads()
        .then(res => {
          // this.loads = res.data.loads
          this.upcomings = res.data.loads.filter(item => item.load.acc_status !== 'Ready to Bill' && item.load.acc_status !== 'Invoiced' && item.load.acc_status !== 'Finalized')
          this.histories = res.data.loads.filter(item => item.load.acc_status === 'Ready to Bill' || item.load.acc_status === 'Invoiced')
          this.finalizes = res.data.loads.filter(item => item.load.acc_status === 'Finalized')
          this.$store.commit('set_loading_action', false)
        })
        .catch(() => {
          this.$store.commit('set_loading_action', false)
        })
    },

    async getAssets() {
      this.trucks = await assetService.getAssetsByType({ type: 'Tractor' })
      this.trailers = await assetService.getAssetsByType({ type: 'Trailer' })
    },

    refresh() {
      this.getLoads()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

const headers2 = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const getLoads = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.load.get}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const create = params => new Promise((resolve, reject) => {
  const formData = new FormData()

  Object.keys(params).forEach(param => {
    if (param === 'operations') {
      formData.append(param, JSON.stringify(params[param]))
    } else {
      formData.append(param, params[param])
    }
  })
 
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.load.create}`, formData, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getLoadById = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.load.edit}/${id}`, { headers })
    .then(res => {
      resolve(res.data.load)
    })
    .catch(err => {
      reject(err)
    })
})

export const update = params => new Promise((resolve, reject) => {
  const formData = new FormData()

  Object.keys(params).forEach(param => {
    if (param === 'operations') {
      formData.append(param, JSON.stringify(params[param]))
    } else {
      formData.append(param, params[param])
    }
  })
 
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.load.update}`, formData, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const deleteLoad = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.load.delete}/${id}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const handles = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.load.handles}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const assign = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.load.assign}`, params, { headers: headers2 })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const readyToBill = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.load.readyToBill}/${id}`, { headers: headers2 })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const createInvoice = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.load.createInvoice}`, params, { headers: headers2 })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const expectMoney = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.load.expectMoney}`, params, { headers: headers2 })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const finalized = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.load.finalized}/${id}`, { headers: headers2 })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})
